/* You can add global styles to this file, and also import other style files */
// @import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


/* Importing Bootstrap SCSS file. */
$primary: #132c51;
$navbar-light-active-color:#132c51;
@import 'bootstrap/scss/bootstrap';
@import "bootstrap-icons/font/bootstrap-icons.css";

.cdk-overlay-container {
    z-index: 99999; 
}

// Generates w-* classes for each breakpoint
$sizes: ( 25: 25%, 50: 50%, 75: 75%, 100: 100%, );
@each $breakpoint in map-keys($grid-breakpoints) {
    @each $size, $length in $sizes {
      @include media-breakpoint-up($breakpoint) {
        .w-#{$breakpoint}-#{$size} {width: $length !important;}
      }
    }
  }